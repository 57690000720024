<template>
  <div id="adduser" class="Content-Page">
    <div id="namePage" class="wrap-Main">
      <div class="box-S4 flex-between-center">
        <!-- <div class="N-Page T-size-36">User Profile</div> -->
        <div class="N-Page T-size-36">{{ title }}</div>
      </div>
    </div>
    <div id="userContent" class="wrap-Main" v-if="u_id != 0">
      <div class="box-S4 flex-between noPadding">
        <div class="box-S4">
          <div class="B-carddetail">
            <div class="panal-title">Display</div>
            <div class="b-row">
              <div class="b-display">
                <!-- <div class="B-display"></div> -->
                <div class="B-display" :style="{
                  backgroundImage:
                    'url(' + formModel.u_imageurl + ') !important',
                }"></div>
              </div>
            </div>
            <div class="b-row">
              <!-- <v-btn class="theme-btn-even btn-upload center w100"> -->
              <v-btn class="theme-btn-even btn-upload center" @click="dialogUpload = true">
                <span class="I-upload"></span>
                <span class="T-size-18">Upload Photo</span>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
      <div class="line marginTop"></div>
    </div>

    <div id="userInfo" class="wrap-Main">
      <div class="box-S2">
        <div class="box-S4">
          <div class="N-Page T-size-24">Staff Personal Information</div>
        </div>
        <div class="box-S4">
          <div class="B-carddetail B-formMain">
            <div class="b-row">
              <div class="box-S2">
                <v-text-field readonly label="Staff Code" v-model="formModel.u_staffcode"></v-text-field>
              </div>
              <div class="box-S2">
                <v-text-field readonly label="Title Name" v-model="formModel.u_tital"></v-text-field>
              </div>
            </div>
            <div class="b-row">
              <div class="box-S2">
                <v-text-field readonly label="First Name" v-model="formModel.u_firstname"></v-text-field>
              </div>
              <div class="box-S2">
                <v-text-field readonly label="Last Name" v-model="formModel.u_lastname"></v-text-field>
              </div>
            </div>
            <div class="b-row">
              <div class="box-S2">
                <v-select readonly :items="itemsListGender" v-model="formModel.u_gender" class="hideMessage"
                  label="Gender"></v-select>
              </div>
              <div class="box-S2">
                <v-text-field readonly label="Nationality" v-model="formModel.u_national_id"></v-text-field>
              </div>
            </div>
            <div class="b-row">
              <div class="box-S2">
                <v-text-field readonly label="Blood Group" v-model="formModel.u_bloodgroup"></v-text-field>
              </div>
              <div class="box-S2">
                <v-select readonly :items="itemsListCrewType" v-model="formModel.u_crewtype" class="hideMessage"
                  label="Crew Type"></v-select>
              </div>
            </div>
            <div class="b-row">
              <div class="box-S2">
                <v-select readonly :items="itemsListOwner" item-text="o_name" item-value="o_id"
                  v-model="formModel.customer" class="hideMessage" label="Customer"></v-select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box-S2" v-show="userdata.cb != 'OAuth2 Microsoft'">
        <div class="box-S4">
          <div class="N-Page T-size-24">Reset Password</div>
        </div>
        <div class="box-S4">
          <div class="B-carddetail B-formMain">
            <div class="b-row">
              <div class="box-S4">
                <v-text-field readonly label="Username" v-model="userdata.u_username"></v-text-field>
              </div>
            </div>
            <div class="b-row">
              <div class="box-S4">
                <v-text-field label="Current Password" v-model="currentPassword" type="password"></v-text-field>
              </div>
            </div>
            <div class="b-row">
              <div class="box-S4">
                <v-text-field label="New Password" v-model="newPassword" type="password"></v-text-field>
              </div>
            </div>
            <div class="b-row">
              <div class="box-S4">
                <v-text-field label="Confirm Password" v-model="confirmPassword" type="password"></v-text-field>
              </div>
            </div>
            <div class="box-S4">
              <div class="b-btnSave">
                <v-btn class="theme-btn-even btn-cancle Bsize150" @click="CancelResetPassword()">
                  <span class="T-size-18">Cancel</span>
                </v-btn>
                <v-btn class="theme-btn-even btn-save Bsize150" @click="ResetPassword()">
                  <!-- <span class="I-save"></span> -->
                  <span class="T-size-18">Chang Password</span>
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="line"></div>
    </div>

    <div id="partBack" class="wrap-Main">
      <div class="box-S4">
        <v-btn text class="ColorTheme-S white--text theme-btn" @click="Back()">
          <span class="I-back"></span>
          <span>Back</span>
        </v-btn>
      </div>
    </div>

    <v-dialog v-model="dialogConfirmResetPassword" persistent max-width="300">
      <v-card id="dialog" class="TF type1">
        <div class="b-content">
          <div class="B-dialog save">
            <div class="t-title">Confirm Reset Password</div>
            <div class="t-des">Are you sure you want to change password?</div>
          </div>
          <div class="box-btn">
            <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="dialogConfirmResetPassword = false">
              <span class="T-size-18">cancel</span>
            </v-btn>
            <v-btn class="theme-btn-even btn-save Bsize100" text @click="ConfirmResetPassword()">
              <span class="T-size-18">Confirm</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogSaveComplete" persistent max-width="300">
      <v-card id="dialog" class="TF type1">
        <div class="b-content">
          <div class="B-dialog confirm">
            <div class="t-title">Reset Password Complete</div>
            <div class="t-des">Reset Password Complete</div>
          </div>
          <div class="box-btn">
            <v-btn class="theme-btn-even btn-ok Bsize100" text @click="dialogSaveComplete = false">
              <span class="T-size-18">OK</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <!-- Add -->
    <v-dialog v-model="dialogWarning" persistent max-width="300">
      <v-card id="dialog" class="TF type1">
        <div class="b-content">
          <div class="B-dialog warning3">
            <div class="t-title">Warning</div>
            <div class="t-des">{{ resultMessage }}</div>
          </div>
          <div class="box-btn">
            <v-btn class="theme-btn-even btn-ok Bsize100" text @click="dialogWarning = false">
              <span class="T-size-18">OK</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogUpload" persistent max-width="50%">
      <v-col>
        <v-button @click="dialogUpload = false" style="color: white">X Clsoe</v-button>
      </v-col>
      <v-card id="dialog">
        <ImageBox ref="ImageBox" @success="UploadSuccess"></ImageBox>
      </v-card>
    </v-dialog>
  </div>
</template>

<script src="//unpkg.com/@feathersjs/client@^4.3.0/dist/feathers.js"></script>
    <script src="//unpkg.com/socket.io-client@^2.3.0/dist/socket.io.js"></script>

<script>
import feathersClientUOA from "../plugins/feathers-client-uoa";
import { startOfDay, endOfDay, format } from "date-fns";
import { th } from "date-fns/locale";
import ImageBox from "../components/upload/ImageBox";

import fillerdate from "../utils/filterdate";

export default {
  name: "UserProfile",
  data: () => ({
    title: "",
    userdata: "",
    u_id: "",
    dialogUpload: false,
    dialogWarning: false,
    resultMessage: "",
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",

    itemsListGender: ["Male", "Female"],
    itemsListOwner: [],
    itemsListRole: [{ r_id: "", r_name: "" }],
    itemsListCrewType: ["ORGANIC CREW", "NON ORGANIC CREW"],

    formModel: {
      u_gender: "Male",
      u_crewtype: "ORGANIC CREW",
    },
    dialogConfirmResetPassword: false,
    dialogSaveComplete: false,
  }),
  props: [],
  components: {
    ImageBox,
  },
  mounted: function () {
    this.userdata = this.$CurrentUser.GetCurrentUser();
    this.u_id = this.$route.query.u_id;
    this.title = this.$route.query.title;
    this.redirect = this.$route.query.redirect;
    this.renderUI();
  },
  computed: {
    formTitle() {
      return this.modeEvent === "create" ? "Add" : "Edit";
    },
  },

  methods: {
    async SelectListOwner() {
      try {
        const q = {};
        if (this.userdata.r_id == 2) {
          q.o_id = this.userdata.o_id;
        }
        var res = await feathersClientUOA
          .service("customer")
          .find({ query: q });
        this.itemsListOwner = res.data;

        this.formModel.customer = {
          o_id: this.userdata.o_id,
          o_name: this.userdata.o_name,
        };
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },
    async SelectListRole() {
      try {
        const q = {};
        if (this.userdata.r_id != 1) {
          q.r_id = 3;
        }
        var res = await feathersClientUOA.service("role").find({ query: q });
        this.itemsListRole = res.data;

        if (this.userdata.r_id != 1) {
          this.formModel.role = { r_id: 3, r_name: "Passenger" };
        } else {
          this.formModel.role = {
            r_id: this.userdata.r_id,
            r_name: this.userdata.r_name,
          };
        }
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },
    async renderUI() {
      await this.SelectListOwner();
      await this.SelectListRole();
      try {
        if (this.u_id != 0) {
          this.formModel = this.userdata;
          this.formModel.customer = {
            o_id: this.formModel.o_id,
            o_name: this.formModel.o_name,
          };

          if (this.formModel.c_id != null) {
            this.formModel.company = {
              c_id: this.formModel.c_id,
              c_name: this.formModel.c_name,
            };
          } else {
            //Set default
            this.formModel.company = {
              c_id: this.itemsListCompany[0].c_id,
              c_name: this.itemsListCompany[0].c_name,
            };
          }

          this.formModel.role = this.formModel.r_id;
          this.formModel.u_safety_training = format(
            new Date(this.formModel.u_safety_training),
            "yyyy-MM-dd",
            { locale: th }
          );
          this.formModel.u_medical_checkup = format(
            new Date(this.formModel.u_medical_checkup),
            "yyyy-MM-dd",
            { locale: th }
          );
        }
      } catch (err) {}
    },

    async UploadSuccess(uploadInfo) {
      // let url = "/uploads/" + uploadInfo.filename;
      let url = uploadInfo.url;
      await this.UpdateImageUrl(url);
      this.formModel.u_imageurl = url;
      this.dialogUpload = false;
    },

    async UpdateImageUrl(url) {
      try {
        let dataEdit = {
          u_imageurl: url,
        };
        await feathersClientUOA
          .service("account")
          .patch(this.formModel.u_id, dataEdit);
      } catch (err) {
        console.log(err);
      } finally {
      }
    },

    Back() {
      this.$router.go(-1);
    },

    async ConfirmResetPassword() {
      this.dialogConfirmResetPassword = false;
      try {
        await feathersClientUOA
          .service("account")
          .patch(this.userdata.u_id, { u_password: this.newPassword });
      } catch (error) {
        alert("catch" + error);
      }
      this.currentPassword = "";
      this.newPassword = "";
      this.confirmPassword = "";
      this.dialogSaveComplete = true;
    },

    async ResetPassword() {
      this.resultMessage = "";

      if (
        this.currentPassword == "" ||
        this.newPassword == "" ||
        this.confirmPassword == ""
      ) {
        this.resultMessage = "Please fill out all of the completely.";
        this.dialogWarning = true;
      } else if (this.newPassword !== this.confirmPassword) {
        this.resultMessage = "Password and confirm password does not match";
        this.dialogWarning = true;
      } else {
        try {
          const response = await feathersClientUOA.authenticate({
            strategy: "local",
            u_username: this.userdata.u_username,
            u_password: this.currentPassword,
          });
        } catch (error) {
          this.resultMessage = "Your current password is missing or incorrect.";
          this.dialogWarning = true;
        }

        if (this.resultMessage == "") {
          this.dialogConfirmResetPassword = true;
        }
      }
    },
    CancelResetPassword() {},
  },
};
</script>

<style>

</style>
